import { isArray } from 'lodash';

import { localizedNavigate } from '@utils/localized-navigate';
import { getQueryParams } from '@utils/query-params';

export const getClubDestinationURL = (
  clubId: string,
  destination?: string | null
) => {
  const allowedDestinations = [
    'details',
    'administration',
    'officers',
    'roster',
    'finances',
  ];

  let updatedDestination = destination;

  if (!destination || !allowedDestinations.includes(destination)) {
    updatedDestination = 'details';
  }

  return `/club/${clubId}/${updatedDestination}`;
};

export const handleGoToClub = (clubId: string) => {
  const queryDestination = getQueryParams()?.destination;

  if (isArray(queryDestination)) {
    return;
  }

  localizedNavigate(getClubDestinationURL(clubId, queryDestination));
};

export const handleGoToDestination = (clubId: string, destination: string) => {
  const queryDestination = getQueryParams()?.destination;

  if (isArray(queryDestination)) {
    return;
  }

  localizedNavigate(getClubDestinationURL(clubId, destination));
};
